<template>
  <div class="ReportRight">
    <!-- 我要投诉 -->
    <div class="tousu_imgs">
      <img class="tousu_img" @click="Eject_Complaint" src="../assets/img/yaotousu.png" alt="" />
      <img class="tousu_img" @click="SettleIn" src="../assets/img/yaoruzhu.png" alt="" />
    </div>
    <a :href="advertisement.url" target="_blank"><img class="Banner_imga" :src="advertisement.img" alt=""></a>
    <!-- 热门投诉 -->
    <div class="Hot_complaints">
      <div class="title">热门投诉</div>
      <div class="Hot_List" v-for="(item,index) in Hot_List" :key="index" @click="G_Details(item.id)">
        <img :src="item.headimgurl" alt="" />
        <div class="Hot_List_title">
          {{item.statement_title}}
        </div>
        <div class="Hot_List_data">{{item.create_time.slice(0,10)}}</div>
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="tanchukuang_box" @mousewheel.prevent v-if="HidePopup ==true">
      <div class="tanchukuang">
        <div class="tanchukuang_top">
          <div>重要通知</div>
          <div @click="Close_Complaint">X</div>
        </div>
        <div class="tanchukuang_content" @mousewheel.stop>
          <div class="H_3">一、用户须知</div>
          <div style="margin-bottom:20rpx;">欢迎使用鹰眼投诉（以下简称“本平台“）。</div>
          1.1本平台（包括但不限于鹰眼投诉、驱动中国网、驱动中国APP等产品及/或服务，以下可简称鹰眼投诉）由北京汇通阳光信息技术有限公司及其关联主体或授权主体
          （以下简称汇通阳光）运营管理维护。在您使用本平台或注册成为本平台用户前请务必仔细阅读以下条款。若一旦您以任何形式使用本平台或成为注册用户，则您接受本平台的服务必须受以下条款的约束。
          1.2本服务协议内容包括以下条款及已经发布的或将来可能发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。本协议是由您与本平台共同签订的，适用于您在本平台的全部活动。在您使用或注册成为用户时，您已经阅读、理解并接受本协议的全部条款及各类规则，并承诺遵守中国现行的法律、法规、规章及其他政府规定，如有违反而导致任何法律后果的发生，您将以自己的名义独立承担所有相应的法律责任。
          1.3本平台有权根据需要不时地制定、修改本协议或各类规则，如本协议及规则有任何变更，一切变更以本平台最新公布条例为准。您应不时地注意本协议及附属规则地变更，若您不同意相关变更，您可以依照规定终止使用本平台服务或注销账户，本平台也有权不经任何告知终止、中止本协议或者限制您进入本平台的全部或者部分板块且不承担任何法律责任。但该终止、中止或限制行为并不能豁免您在本平台已经进行的交易下所应承担的义务。
          1.4您确认本服务协议后，本服务协议即在您和本平台之间产生法律效力。您只要点击协议正本下方的"确认"按钮并按照本平台规定的注册程序成功注册为用户，您的行为既表示同意并签署了本服务协议。本服务协议不涉及您与本平台的其他用户之间因网上交易而产生的法律关系及法律纠纷，但您在此同意将全面接受和履行与本平台其他用户在本平台签订的任何电子法律文本，并承诺按该法律文本享有和/或放弃相应的权利、承担和/或豁免相应的义务。
          <div class="H_3">二、服务内容（变更、终止、中断）</div>
          2.1鹰眼投诉的具体内容由汇通阳光及其关联主体根据实际情况提供，包括但不限于授权用户通过其账号，使用鹰眼投诉/驱动中国发布观点、评论，汇通阳光有权对其提供的鹰眼投诉/驱动中国或产品形态进行升级或其他调整，并将及时更新页面/告知用户。
          2.2用户理解，汇通阳光仅提供与鹰眼投诉/驱动中国相关的技术服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。
          2.3鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他汇通阳光无法控制的情形），用户同意汇通阳光有权随时中断或终止部分或全部的驱动中国，若发生该等中断或中止驱动中国的情形，汇通阳光将尽可能及时通过网页公告、系统通知、私信、短信提醒或其他合理方式通知受到影响的用户。
          2.4用户理解，汇通阳光需要定期或不定期地对提供鹰眼投诉/驱动中国的平台（如互联网平台、移动网络、客户端软件等）或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，汇通阳光无需为此承担任何责任，但汇通阳光应尽可能事先进行通告。
          2.5如发生下列任何一种情形，汇通阳光有权随时中断或终止向用户提供本协议项下的鹰眼投诉/驱动中国而无需对用户或任何第三方承担任何责任：
          （1）用户提供的个人资料不真实；
          （2）用户违反法律法规、国家政策或本协议及汇通阳光公示的各项规则、规范；
          （3）用户选择将鹰眼投诉/驱动中国账号与汇通阳光合作的第三方账号进行绑定的，除用户自行解除绑定关系外，如发生下列任何一种情形，用户已绑定的第三方账号也有可能被解除绑定而汇通阳光无需对用户或任何第三方承担任何责任：
          （4）用户违反法律法规国家政策、本协议或鹰眼投诉/驱动中国不是制定的协议的；用户违反第三方账号服务商的用户协议或其相关规定的；
          （5）发生其他汇通阳光认为需要解除绑定的情形。
          <div class="H_3">三、使用规则</div>3.1本平台只接受持有有效证件的18周岁以上的具有完全民事行为能力的自然人成为平台用户。如您不符合资格，请勿注册。本平台保留随时中止或终止您用户资格的权利。
          3.2您注册成功后，不得将本平台的用户名转让给第三方或者授权给第三方使用。您确认，您用您的用户名和密码登录本平台后在本平台的一切行为均代表您并由您承担相应的法律后果。
          3.3您有义务在注册时提供自己的真实资料，并保证诸如电子邮件地址、联系电话、联系地址、邮政编码等内容的有效性及安全性。如您与其他用户产生诉讼，其他用户有权通过司法部门要求平台提供相关资料。
          3.4用户注册成功后，驱动中国将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
          3.5用户须对在鹰眼投诉/驱动中国的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；否则鹰眼投诉/驱动中国有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。
          3.6用户直接或通过各类方式（如RSS源和站外API引用等）间接使用鹰眼投诉/驱动中国服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用鹰眼投诉/驱动中国所提供的全部服务。
          3.7用户通过鹰眼投诉/驱动中国发表的信息为公开的信息，其他第三方均可以通过鹰眼投诉/驱动中国获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在驱动中国上进行发表。
          3.8用户承诺不得以任何方式利用鹰眼投诉/驱动中国直接或间接从事违反中国法律以及社会公德的行为，鹰眼投诉/驱动中国有权对违反上述承诺的内容予以删除。
          <div class="H_3">四、知识产权</div>4.1汇通阳光是鹰眼投诉平台的所有权及知识产权权利人。
          4.2汇通阳光是鹰眼投诉/驱动中国产品及服务的所有权及知识产权权利人。上述鹰眼投诉产品或服务指的是汇通阳光、或其关联主体、授权主体等通过鹰眼投诉/驱动中国平台为用户提供的包括但不限于信息发布分享、关系链拓展、便捷辅助工具、平台应用程序、公众开放平台等功能、软件、服务等。
          4.3汇通阳光是鹰眼投诉平台及驱动中国产品及/或服务中所有信息内容的所有权及知识产权权利人。前述信息内容包括但不限于程序代码、界面设计、版面框架、数据资料、账号、文字、图片、图形、图表、音频、视频等，但按照法律法规规定应由相关权利人享有权利的内容除外。
          4.4用户在使用鹰眼投诉的过程中，可能会使用到由第三方针对鹰眼投诉/驱动中国开发的在鹰眼投诉/驱动中国平台运行的软件或提供的服务，用户除遵守本协议相关规定以外，还应遵守该等第三方的相关规定，并尊重第三方权利人对其软件、服务及其所包含内容的相关权利。
          鉴于以上，用户理解并同意：
          未经汇通阳光及相关权利人同意，用户不得对上述功能、软件、服务进行反向工程 （reverse
          engineer）、反向编译（decompile）或反汇编（disassemble）等；同时，不得将上述内容或资料在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者用于其他任何目的。
          在尽商业上的合理努力的前提下，汇通阳光并不就上述功能、软件、服务及其所包含内容的延误、不准确、错误、遗漏或由此产生的任何损害，以任何形式向用户或任何第三方承担任何责任；
          <div class="H_3">五、用户隐私制度</div>
          5.1用户知悉并同意，为便于向用户提供更好的服务，鹰眼投诉/驱动中国将在用户自愿选择服务或者提供信息的情况下收集用户的个人信息，并将这些信息进行整合。在用户使用鹰眼投诉/驱动中国服务时，服务器会自动记录一些信息，包括但不限于URL、IP地址、浏览器类型、使用语言、访问日期和时间等。为方便用户登录或使用鹰眼投诉/驱动中国的服务，鹰眼投诉/驱动中国在有需要时将使用cookies等技术，并将收集到的信息发送到对应的服务器。用户可以选择接受或者拒绝cookies。如用户选择拒绝cookies，则用户有可能无法登录或使用依赖于cookies的服务或者功能。鹰眼投诉/驱动中国收集的信息将成为鹰眼投诉/驱动中国常规商业档案的一部分，且有可能因为转让、合并、收购、重组等原因而被转移到鹰眼投诉/驱动中国的继任公司或者指定的一方。鹰眼投诉/驱动中国同意善意使用收集的信息，且采取各项措施保证信息安全。
          5.2尊重用户个人隐私是鹰眼投诉/驱动中国的一项基本政策。所以，鹰眼投诉/驱动中国不会公开或透露用户的注册资料及保存在鹰眼投诉/驱动中国各项服务中的非公开内容，除非鹰眼投诉/驱动中国在诚信的基础上认为透露这些信息在以下几种情况是必要的：
          （1）事先获得用户的明确授权；或
          （2）遵守有关法律规定，包括在国家有关机关查询时，提供用户的注册信息、用户在鹰眼投诉/驱动中国的网页上发布的信息内容及其发布时间、互联网地址或者域名；或
          （3）保持维护鹰眼投诉/驱动中国的知识产权和其他重要权利；或
          （4）在紧急情况下竭力维护用户个人和社会大众的隐私安全；或
          （5）根据本条款相关规定或者鹰眼投诉/驱动中国认为必要的其他情况下。
          5.3鹰眼投诉可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与鹰眼投诉/驱动中国同等的保护用户隐私的责任，则鹰眼投诉/驱动中国可将用户信息提供给该第三方 。
          <div class="H_3">六、不可抗力条款</div>
          鹰眼投诉对不可抗力导致的损失不承担责任。本服务条款所指不可抗力包括：天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。
          <div class="H_3">七、禁止服务的商业化</div>
          用户承诺，非经鹰眼投诉/驱动中国同意，用户不能利用鹰眼投诉/驱动中国各项服务进行销售或其他商业用途。如用户有需要将服务用于商业用途，应书面通知鹰眼投诉/驱动中国并获得驱动中国的明确授权。
          <div class="H_3">八、法律适用和争议解决</div>
          根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，鹰眼投诉/驱动中国有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，鹰眼投诉将会直接在鹰眼投诉/驱动中国平台上公布修改之后的协议内容，该公布行为视为鹰眼投诉/驱动中国已经通知用户修改内容。鹰眼投诉也可采用电子邮件或私信的传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。
          如果不同意鹰眼投诉对本协议相关条款所做的修改，用户有权并应当停止使用鹰眼投诉。如果用户继续使用鹰眼投诉，则视为用户接受鹰眼投诉对本协议相关条款所做的修改。
          本协议适用中华人民共和国的法律，并且排除一切冲突法规定的适用。
          如出现任何纠纷，用户和汇通阳光一致同意将纠纷交由汇通阳光所在地有管辖权的法院管辖。
          <div class="H_3">九、有关著作权的投诉</div>
          如果个人或单位发现鹰眼投诉上存在侵犯自身合法权益的内容，请与鹰眼投诉取得联系（邮箱：business@qudong.com）。为了保证问题能够及时有效地处理，请务必提交真实有效、完整清晰的材料，否则不予受理。
          请使用以下格式（包括各条款的序号）：
          权利人对涉嫌侵权内容拥有商标权、著作权和/或其他依法可以行使权利的权属证明；如果举报人非权利人，请举报人提供代表企业进行举报的书面授权证明。
          充分、明确地描述侵犯了权利人合法权益的内容，提供涉嫌侵权内容在驱动中国上的具体页面地址，指明涉嫌侵权内容中的哪些内容侵犯了上述列明的权利人的合法权益；
          权利人具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、机构登记信息证明复印件加盖公章（对机构）、通信地址、电话号码、传真和电子邮件； 在侵权举报中加入如下关于举报内容真实性的声明：
          （1）我本人为所举报内容的合法权利人；
          （2）我举报的发布在鹰眼投诉中的内容侵犯了本人相应的合法权益；
          （3）如果本侵权举报内容不完全属实，本人将承担由此产生的一切法律责任。

        </div>
        <div class="tanchukuang_bottom">
          <div class="tanchukuang_bottom_btn" @click="Close_Complaint">不同意</div>
          <div class="tanchukuang_bottom_btn1" @click="G_Complaints_Submit">同意</div>
        </div>
      </div>
    </div>
    <img class="paihang_img" src="../assets/img/paihang.png" alt="" />
    <!-- 排行榜 -->
    <van-tabs class="zheshizidingyide" color="#1f72d9">
      <van-tab title="红榜" class="paihangbang">
        <div class="paihang_list" v-for="(item, index) in Ranking" :key="index" @click="ComingSoon">
          <div class="paihangbang_left">
            <img v-if="index == 0" class="paihangbang_left_left" src="../assets/img/one.png" alt="" />
            <img v-if="index == 1" class="paihangbang_left_left" src="../assets/img/two.png" alt="" />
            <img v-if="index == 2" class="paihangbang_left_left" src="../assets/img/three.png" alt="" />
            <div v-if="index > 2" class="paihang_num">
              {{ index + 1 }}
            </div>
            <img class="paoihang_icon" :src="item.img" alt="" />
          </div>
          <div class="paihangbang_right">{{ item.name }}</div>
        </div>
      </van-tab>
      <van-tab title="黑榜" class="paihangbang">
        <div class="paihang_list" v-for="(item, index) in Rankings" :key="index" @click="ComingSoon">
          <div class="paihangbang_left">
            <img v-if="index == 0" class="paihangbang_left_left" src="../assets/img/one.png" alt="" />
            <img v-if="index == 1" class="paihangbang_left_left" src="../assets/img/two.png" alt="" />
            <img v-if="index == 2" class="paihangbang_left_left" src="../assets/img/three.png" alt="" />
            <div v-if="index > 2" class="paihang_num">
              {{ index + 1 }}
            </div>
            <img class="paoihang_icon" :src="item.img" alt="" />
          </div>
          <div class="paihangbang_right">{{ item.name }}</div>
        </div>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import Axios from "axios";
import md5 from 'js-md5';
import Swiper from "swiper";
import {advert} from "../api/api"
export default {
  data() {
    return {
      Hot_List: [],
      Ranking: [
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
      ],
      Rankings: [
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
      ],
      HidePopup: false, //弹窗
      advertisement:'',
    };
  },
  methods: {
    ComingSoon(){
      this.$message('敬请期待');
    },
    // 打开弹窗
    Eject_Complaint() {
    let tokens = localStorage.getItem("token");
      if(tokens){
          this.HidePopup = true;
      }else{
        this.$confirm('您未登录,是否前往登录页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: "login" });     //去登录
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '用户已取消'
          // });          
        });
      } 
    },
    Close_Complaint() {
      this.HidePopup = false;
    },
    G_Complaints_Submit() {
      this.$router.push({ name: "Complaints_Submit" });
    },
    // 跳转入驻企业
    SettleIn() {
      let tokens = localStorage.getItem("token");
       if(tokens){
        this.$router.push({ name: "SettleIn" });
       }else{
         this.$confirm('您未登录,是否前往登录页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: "login" });     //去登录
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '用户已取消'
          // });          
        });
       }
    },
    complates() {
      var that=this;
      Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/complaint_index",
          params: { 
            type:3,
            key:md5('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKB'),
          },
        }).then((res) => {
        //console.log(res);
        that.Hot_List = res.data.data.data;
        });
      // complaint_index({ type: 3 }).then((res) => {
      //   let that = this;
      //   //console.log(res);
      //   that.Hot_List = res.data.data.data;
      // });
    },
    // 去热门投诉详情
    G_Details(id){
       //console.log(id);
      this.$router.push({ name: "Details", query: { id: id } });
    },
    Advert(){
       Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/advert",
          params: {},
        }).then((res) => {
        console.log(res);
          this.advertisement=res.data.data;
        });
    },    
  },
  mounted() {
    this.complates();
    this.Advert();
  },
};
</script>

<style scoped>
.ReportRight {
  width: 280px;
  /* background:#fff; */
}
.tousu_imgs {
  display: flex;
  justify-content: space-between;
}
.tousu_imgs .tousu_img {
  width: 47%;
  height: 64px;
  cursor: pointer;
  border-radius: 8px;
  object-fit: none;
}
.Banner_imga {
  margin-top: 20px;
  width: 280px;
  height: 160px;
}
.Hot_complaints {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 15px;
  background: #fff;
}
.Hot_complaints .title {
  width: 100%;
  font-size: 18px;
  font-weight: 550;
  color: #1f72d9;
  padding: 10px 0;
  margin-left: 4%;
  margin-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
}

.Hot_complaints .Hot_List {
  width: 92%;
  height: 35px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Hot_complaints .Hot_List img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.Hot_List_title {
  width: 150px;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Hot_List_data {
  font-size: 14px;
  color: #999999;
  cursor: pointer;
}
/* 弹窗 */
.tanchukuang_box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 6666;
  background: rgba(0, 0, 0, 0.4);
}
.tanchukuang {
  width: 600px;
  height: 400px;
  z-index: 6666;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.tanchukuang_top {
  width: 560px;
  height: 56px;
  background: #1f72d9;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  color: #fff;
}
.tanchukuang_top div:nth-child(1) {
  font-size: 16px;
}
.tanchukuang_top div:nth-child(2) {
  font-size: 20px;
  cursor: pointer;
}
.tanchukuang_content {
  width: 550px;
  height: 230px;
  margin: 0 auto;
  margin-top: 30px;
  overflow-y: scroll !important;
  white-space: pre-wrap;
}
.tanchukuang_bottom {
  width: 600px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px -2px 16px 0px rgba(3, 44, 95, 0.12);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tanchukuang_bottom_btn {
  width: 160px;
  height: 48px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  line-height: 48px;
  font-size: 20px;
  color: #333333;
  margin-right: 30px;
  cursor: pointer;
}
.tanchukuang_bottom_btn1 {
  width: 160px;
  height: 48px;
  background: #1f72d9;
  border-radius: 4px;
  color: #ffffff;
  line-height: 48px;
  cursor: pointer;
  font-size: 20px;
}
/* 排行榜 */
.zheshizidingyide {
  background: #fff;
  padding-bottom: 20px;
}
.paihangbang {
  border-top: 1px solid #ccc;
}
.paihang_list {
  padding: 10px 0;
  width: 85%;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  cursor: pointer;
}
>>> .van-tabs__line {
  height: 1px !important;
  width: 80px;
}
.paihang_list div:nth-child(1) {
  display: flex;
}
.paihangbang_left {
  display: flex;
  align-items: center;
}
.paihangbang_left_left {
  width: 23px;
  height: 29px;
}
.paihang_list .paoihang_icon {
  margin-left: 30px;
}
.paihang_list .paihang_num {
  width: 20px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  border-radius: 5px;
  color: #fff;
}
.paihangbang_right {
  margin-left: 20px;
}
.paihang_img {
  width: 100%;
  margin-top: 20px;
}
</style>